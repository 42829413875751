import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { WebLink } from '@news-mono/web-common'
import {
    breakpoint,
    breakpointMax,
    calcRem,
    colors,
    fonts,
    metrics,
    themedValue,
    transition,
} from '../..'
import {
    StyledSubscribeWithGoogleButton,
    StyledSubscriptionCancellationMessage,
    StyledSubscriptionPackage2022,
    StyledSubscriptionPackageButton,
} from '../../user-registration/breach/components/SubscriptionPackage/SubscriptionPackage.styled'
import { SwgCampaignOffer } from '../campaigns'
import {
    BottomTextWrap,
    InfoText,
    InfoTextLink,
} from '../../user-registration/breach/components/PuzzlesBreach/PuzzlesBreachwall.styled'
import { TextLink } from '../../typography/TextLink/TextLink'
import { StyledTerms2022 } from '../../user-registration/breach/components/BreachWallContent/BreachWallContent.styled'

const deviceCompNegativeMargin = '-160px'

const checkmark = require('./assets/check.svg')
const plusmark = require('./assets/plus.svg')

const customBreakpoints = {
    tablet: 'sm',
    desktop: 'md',
} as const
export const StyledPromoPageContainer = styled('div')(({ theme }) => ({
    fontFamily: theme.fonts.sansSerif,
    fontSize: calcRem(18),
}))

export interface StyledSwgCampaignProps {
    modifier?: SwgCampaignOffer
}

export const StyledIntroCopyArea = styled('div')<StyledSwgCampaignProps>(
    ({ theme, modifier }) => [
        {
            background:
                modifier == 'university-offer'
                    ? tokens.thewest.colors.palette.universityOffer
                          .campaignTurquoise
                    : `linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 100%), ${theme.sectionValues.primaryColor}`,
            backgroundBlendMode: 'overlay, normal',
            color: tokens.thewest.colors.palette.white,
            padding: theme.margins.lg,
            paddingBottom: 0,
            overflow: 'hidden',
            marginBottom: '-12%',

            '@media (max-width: 374px)': {
                marginBottom: 0,
            },

            [breakpoint(customBreakpoints.tablet)]: {
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                margin: '0',
            },

            // 480px / 130px / 640px
            [breakpoint(customBreakpoints.desktop)]: {
                overflow: 'visible',
                position: 'relative',
                gridTemplateColumns: '343px 1fr',
                padding: calcRem(72, 120),
                borderRadius: calcRem(32),
                marginBottom: calcRem(-32),
            },
        },
        modifier === 'footy-pack-2025' && {
            marginBottom: 0,
            paddingBottom: theme.margins.lg,

            [breakpoint(customBreakpoints.tablet)]: {
                borderRadius: 0,
                marginBottom: 0,
                display: 'flex',
                justifyContent: 'center',
            },
            [breakpoint(customBreakpoints.desktop)]: {
                padding: calcRem(120),
            },
        },
    ],
)

export const StyledIntroCopyArea2025 = styled('div')<StyledSwgCampaignProps>(
    ({ theme, modifier }) => [
        {
            color: tokens.thewest.colors.palette.white,
            background:
                modifier == 'university-offer'
                    ? tokens.thewest.colors.palette.universityOffer
                          .campaignTurquoise
                    : 'grey',
            marginBottom: 0,
            paddingTop: calcRem(32),
            paddingBottom: calcRem(24),
            display: 'flex',
            justifyContent: 'center',
            [breakpoint(customBreakpoints.desktop)]: {
                paddingTop: calcRem(64),
            },
        },
    ],
)

export const StyledIntroCopyAreaInner = styled('div')(({ theme }) => [
    {
        maxWidth: '100%',
        width: '100%',

        display: 'grid',
        gap: `${calcRem(24)} 0%`,
        gridTemplateColumns: '100% 1fr',

        [breakpoint(customBreakpoints.desktop)]: {
            maxWidth: theme.siteMetrics.mainContentWidth,
            width: theme.siteMetrics.mainContentWidth,

            gap: `${calcRem(24)} 10%`,
            gridTemplateColumns: '480px 1fr',
        },
    },
])

export const StyledIntroTitle = styled('h1')<
    StyledSwgCampaignProps & {
        headerFontSize?: number
    }
>(({ modifier, headerFontSize }) => [
    {
        fontSize: calcRem(46),
        lineHeight: 1,
        letterSpacing: '-0.6px',
        whiteSpace: 'pre-line',

        [breakpoint(customBreakpoints.tablet)]: {
            gridColumn: '1/3',
            paddingRight: '30vw',
            fontSize: calcRem(headerFontSize || 64),
        },

        [breakpoint(customBreakpoints.desktop)]: {
            margin: 0,
            paddingRight: 0,
        },
    },
    // Split out the ::after so we don't deal with a ghost after on the footy-pack-2025
    modifier !== 'footy-pack-2025' && {
        [breakpoint(customBreakpoints.desktop)]: {
            '&::after': {
                content: `''`,
                display: 'block',
                width: calcRem(48),
                marginTop: calcRem(20),
                borderBottom: `3px solid ${tokens.thewest.colors.palette.white}`,
            },
        },
    },
    // Styling for TheWest SwG Footy 2025 Campaign Offer
    modifier === 'footy-pack-2025' && {
        fontWeight: 700,
        margin: 0,

        [breakpoint(customBreakpoints.tablet)]: {
            gridColumn: '1 / 2',
            display: 'flex',
            paddingRight: 0,

            fontSize: calcRem(64),
            lineHeight: calcRem(76),
        },
    },
])

export const StyledIntroDescription = styled('p')<StyledSwgCampaignProps>(
    ({ modifier, theme }) => [
        // Split these out so they don't fight us with the footy-pack-2025
        modifier !== 'footy-pack-2025' && {
            '&:first-of-type': {
                marginBottom: '160px',
                whiteSpace: 'pre-line',

                '@media (max-width: 374px)': {
                    marginBottom: theme.margins.lg,
                },

                [breakpoint(customBreakpoints.tablet)]: {
                    marginBottom: theme.margins.lg,
                },
            },
        },
        // Style the footy-pack-2025 campaign offer
        modifier === 'footy-pack-2025' && {
            fontFamily: fonts.thewest.serif,
            fontSize: calcRem(18),
            fontWeight: 400,
            lineHeight: calcRem(28),
            margin: 0,

            gridRow: 2,
            gridColumn: 1,

            [breakpoint(customBreakpoints.tablet)]: {
                marginBottom: 0,
                gridColumn: '1 / 2',
            },
        },
    ],
)

export const StyledDeviceCompContainer = styled('div')<StyledSwgCampaignProps>(
    ({ theme, modifier }) => [
        {
            width: '100vw',
            textAlign: 'center',
            margin: `0 -${theme.margins.lg}px`,
            marginBottom: deviceCompNegativeMargin,
            position: 'relative',

            '@media (max-width: 374px)': {
                marginBottom: 0,
            },

            [breakpoint(customBreakpoints.tablet)]: {
                background: 'none',
                margin: 0,
                width: 'auto',
            },

            img: {
                width: calcRem(426),
                transform: `translateY(${deviceCompNegativeMargin}) translateX(20%)`,

                '@media (max-width: 374px)': {
                    display: 'none',
                },

                [breakpoint(customBreakpoints.tablet)]: {
                    transform: 'translateY(-60px)',
                },

                [breakpoint(customBreakpoints.desktop)]: {
                    width: 'auto',
                    position: 'absolute',
                    left: 0,
                    transform: 'translateX(12%)',
                },
            },
        },
        // Style the uni campaign offer
        modifier === 'university-offer' && {
            img: {
                transform: 'translate(0, -160px)',
                width: '100%',

                [breakpoint(customBreakpoints.tablet)]: {
                    transform: 'translateY(-40px)',
                },
                [breakpoint(customBreakpoints.desktop)]: {
                    width: 'auto',
                    minWidth: '600px',
                    transform: 'translateX(12%) translateY(-14px)',
                },
            },
        },
        // Style the footy-2025 campaign offer
        modifier === 'footy-pack-2025' && {
            margin: 0,
            marginBottom: 0,
            gridRow: 3,
            gridColumn: 1,

            width: '100%',
            display: 'flex',
            alignItems: 'center',

            [breakpoint(customBreakpoints.desktop)]: {
                gridRow: '1 / 3',
                gridColumn: '2 / 3',
            },

            img: {
                width: '100%',
                height: 'auto',
                transform: 'translate(0px, 0px)',

                '@media (max-width: 374px)': {
                    display: 'block',
                },

                [breakpoint(customBreakpoints.tablet)]: {
                    transform: 'translate(0px, 0px)',
                },
                [breakpoint(customBreakpoints.desktop)]: {
                    transform: 'translate(0px, 0px)',
                },
            },
        },
    ],
)

export const StyledDetailsCopyArea = styled('div')<StyledSwgCampaignProps>(
    ({ theme, modifier }) => [
        {
            background: tokens.thewest.colors.palette.greyErmine,
            padding: calcRem(64, 32, 32),
            borderRadius: calcRem(0, 0, 32, 32),

            [breakpoint('sm')]: {
                padding: theme.margins.lg,
            },

            [breakpoint(customBreakpoints.desktop)]: {
                padding: calcRem(64, 120),
                display: 'grid',
                gridGap: calcRem(170),
                gridTemplateColumns: 'repeat(2, 1fr)',
                alignItems: 'center',
            },
        },
        // Styling for the footy-pack-2025
        modifier === 'footy-pack-2025' && {
            background: '#FFF',

            [breakpoint(customBreakpoints.tablet)]: {
                padding: calcRem(64),
                marginLeft: 0,
            },
            [breakpoint(customBreakpoints.desktop)]: {
                display: 'flex',
                justifyContent: 'center',

                marginLeft: calcRem(-29),
            },
        },
        // Styled for the annual-package
        modifier === 'annual-package' && {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gridGap: calcRem(18),

            [breakpoint(customBreakpoints.tablet)]: {
                marginLeft: 0,
                gridGap: calcRem(18),
                padding: calcRem(48),
            },
            [breakpoint(customBreakpoints.desktop)]: {
                padding: calcRem(80),
                display: 'flex',
                gridGap: calcRem(18),

                marginLeft: 0,
            },
            [`${StyledTerms2022}`]: {
                background: 'none',
                width: '100%',
            },
        },
    ],
)

export const StyledDetailsCopyAreaInner2025 = styled(
    'div',
)<StyledSwgCampaignProps>(({ theme, modifier }) => [
    {
        display: 'flex',
        flexDirection: 'column',
        gap: calcRem(40),
        width: '100%',
        maxWidth: calcRem(1250),
        padding: metrics.thewest.margins.md,
        paddingTop: calcRem(40),
        justifySelf: 'center',
        [breakpoint(customBreakpoints.tablet)]: {
            padding: metrics.thewest.margins.lg,
        },
        [breakpoint(customBreakpoints.desktop)]: {
            flexDirection: 'row',
            display: 'flex',
            gap: calcRem(90),
            paddingTop: calcRem(64),
            paddingBottom: calcRem(64),
        },
    },
])

export const StyledDetailsCopyAreaInner = styled('div')(({ theme }) => [
    {
        maxWidth: '100%',
        width: '100%',

        display: 'grid',
        gridGap: calcRem(32, 0),
        gridTemplateColumns: '100% 1fr',

        [breakpoint(customBreakpoints.desktop)]: {
            maxWidth: theme.siteMetrics.mainContentWidth,
            width: theme.siteMetrics.mainContentWidth,

            gridTemplateColumns: '640px 1fr',
            gridGap: calcRem(170),
        },
    },
])

export const StyledCallToActionArea = styled('div')<
    StyledSwgCampaignProps & { hideSwgBtn?: boolean }
>(({ theme, hideSwgBtn, modifier }) => [
    {
        h2: {
            margin: 0,
            fontSize: calcRem(40),
        },

        h3: {
            fontSize: calcRem(24),
        },

        [breakpoint(customBreakpoints.desktop)]: {
            width: calcRem(320),
        },

        [`${StyledSubscribeWithGoogleButton}, ${StyledSubscriptionPackageButton}`]:
            {
                width: '100%',
                height: '54px',
                '&::after': {
                    maxWidth: '228px',
                },
                [breakpoint(customBreakpoints.desktop)]: {
                    width: calcRem(300),
                },
                display: hideSwgBtn ? 'none' : undefined,
            },

        [`${StyledSubscriptionPackageButton}`]: {
            marginTop: 0,
        },
    },
    //Styling for university-offer
    modifier === 'university-offer' && {
        backgroundColor: tokens.thewest.colors.palette.greyErmine,
        padding: calcRem(48),
        borderRadius: calcRem(12),
        width: '100%',
        [breakpoint(customBreakpoints.desktop)]: {
            width: '100%',
        },
    },
    // Styling for the footy-pack-2025
    modifier === 'footy-pack-2025' && {
        height: 'fit-content',

        h2: {
            lineHeight: '120%',
            fontSize: calcRem(38),
            fontWeight: 700,
            fontFamily: fonts.thewest.sansSerif,

            marginBottom: calcRem(16),

            [breakpoint(customBreakpoints.tablet)]: {
                fontSize: calcRem(48),
            },
        },

        [breakpoint(customBreakpoints.desktop)]: {
            width: '100%',
            padding: calcRem(64, 48),

            borderRadius: calcRem(16),
            border: `${calcRem(1)} solid #E5E5E5`,
        },

        [`${StyledSubscribeWithGoogleButton}, ${StyledSubscriptionPackageButton}`]:
            {
                margin: calcRem(32, 0, 0, 0),
            },
        [`${StyledSubscriptionCancellationMessage}`]: {
            marginBottom: calcRem(32),
        },

        [`${BottomTextWrap}`]: {
            marginTop: 0,
        },
        [`${InfoText}, ${InfoTextLink}`]: {
            margin: 0,

            color: '#2B2D2E',

            fontFamily: fonts.thewest.sansSerif,
            fontSize: calcRem(18),
            fontWeight: 400,
            lineHeight: calcRem(28),
        },
        [`${InfoTextLink}`]: {
            color: theme.sectionValues.primaryColor,
        },
    },
])
export const StyledBenefitsListWrappingComponent = styled(
    'div',
)<StyledSwgCampaignProps>(({ modifier }) => [
    modifier === 'footy-pack-2025' && {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',

        gridColumn: 1,
        gridRow: 2,

        [breakpoint(customBreakpoints.desktop)]: {
            gridColumn: 2,
            gridRow: 1,

            display: 'flex',

            margin: 'auto 0',
        },
    },
    modifier === 'university-offer' && {
        alignContent: 'center',
        width: '100%',
    },
])

export const StyledOriginalSubscriptionPrice = styled(
    'p',
)<StyledSwgCampaignProps>(({ theme, modifier }) => [
    {
        margin: 0,
        fontSize: calcRem(18),
        fontWeight: 700,
        textDecoration: 'line-through',
        color: themedValue(theme, {
            thewest: colors.thewest.greyGorilla,
            perthnow: colors.perthnow.greyBasalt90,
            sevennews: colors.sevennews.charade,
            fallback: undefined,
        }),
    },
    // Styling for the footy-pack-2025 & university offer
    (modifier === 'footy-pack-2025' || modifier === 'university-offer') && {
        color: tokens.thewest.colors.text.secondary,
        fontFamily: fonts.thewest.serif,
        fontSize: calcRem(20),
        fontWeight: 500,
        lineHeight: calcRem(28),

        marginBottom: calcRem(8),
    },
])

export const StyledSubscriptionPackagePrice = styled(
    'p',
)<StyledSwgCampaignProps>(({ theme, modifier }) => [
    {
        color: theme.sectionValues.primaryColor,
        fontSize: calcRem(40),
        margin: 0,
        marginBottom: calcRem(32),
        lineHeight: 1,
    },
    // Styling for the university-offer
    modifier === 'university-offer' && {
        color: tokens.thewest.colors.brand,
        fontFamily: fonts.thewest.serif,
        fontSize: calcRem(20),
        fontWeight: 700,
        letterSpacing: calcRem(-0.5),
        maxWidth: calcRem(256),
        lineHeight: calcRem(28),
        marginBottom: 0,
        [breakpoint(customBreakpoints.tablet)]: {
            fontSize: calcRem(28),
            lineHeight: calcRem(36),
        },
    },
    // Styling for the footy-pack-2025
    modifier === 'footy-pack-2025' && {
        fontFamily: fonts.thewest.serif,
        fontSize: calcRem(32),
        fontWeight: 500,

        width: 'fit-content',
        marginBottom: 0,
    },
])

export const StyledSubscriptionPackagePriceContainer = styled(
    'div',
)<StyledSwgCampaignProps>(({ theme, modifier }) => [
    {
        display: 'flex',
        flexDirection: 'row',
        gap: calcRem(8),
        marginBottom: calcRem(16),
    },
    modifier === 'university-offer' && {
        flexDirection: 'column',
        gap: calcRem(16),
        marginBottom: 0,
    },
])
export const StyledSubscriptionTerms = styled('p')<
    StyledSwgCampaignProps & {
        terms: 'price' | 'billing'
    }
>(({ terms, modifier }) => [
    {
        margin: 0,
        marginTop: 'auto',
        color: tokens.thewest.colors.text.secondary,

        fontSize: calcRem(14),
        fontFamily: fonts.thewest.sansSerif,
        fontWeight: 400,
        lineHeight: calcRem(16),
    },
    // Price Terms extra Styling
    terms === 'price' && {
        fontWeight: 500,
        fontSize: calcRem(16),
        lineHeight: calcRem(18),
    },
    modifier === 'university-offer' && {
        fontSize: calcRem(16),
        fontWeight: 500,
    },
])

export const StyledBenefitsList = styled('ul')({
    paddingLeft: '1rem',

    li: {
        paddingLeft: calcRem(10),
    },

    'li::marker': {
        content: `url(${checkmark})`,
        width: 12,
        height: 12,
    },

    // 'li:last-of-type::marker': {
    //     content: `url(${plusmark})`,
    // },
})

export const StyledOrderedList = styled('ol')({
    paddingLeft: '1rem',

    li: {
        paddingLeft: calcRem(10),
    },
})

export const StyledWestRewardsLink = styled(WebLink)({
    color: tokens.thewest.colors.palette.black,

    '&:hover': {
        textDecoration: 'none',
        color: tokens.thewest.colors.actions.link.default,
    },

    '&:focus': {
        background:
            tokens.thewest.colors.actions.button.primary.background.default,
        color: tokens.thewest.colors.palette.white,
    },
})

// @ref {libs/component-library/src/user-registration/breach/components/BreachWallContent/BreachWallContent.styled.tsx}
const StyledButton = styled('button')(({ theme }) => ({
    display: 'inline-block',
    border: `1px solid ${colors.white}`,
    padding: calcRem(theme.margins.sm, theme.margins.xl),
    marginTop: calcRem(theme.margins.lg),
    background: 'none',
    color: colors.white,
    borderRadius: calcRem(2),
    fontFamily: theme.fonts.sansSerif,
    fontWeight: 700,
    fontSize: calcRem(13),
    textDecoration: 'none',
    textTransform: 'uppercase',
    cursor: 'pointer',
    ...transition({ properties: ['background', 'color'] }),

    '&:hover': {
        backgroundColor: colors.white,
        color: themedValue(theme, {
            thewest: colors.thewest.greyGorilla,
            perthnow: colors.perthnow.blueKyanite,
            sevennews: colors.sevennews.red,
            fallback: undefined,
        }),
    },

    '&:focus': {
        backgroundColor: themedValue(theme, {
            thewest: colors.thewest.greyGorilla,
            perthnow: colors.perthnow.pinkThulite,
            sevennews: colors.sevennews.red,
            fallback: undefined,
        }),
        borderColor: themedValue(theme, {
            thewest: colors.thewest.greyGorilla,
            perthnow: colors.perthnow.pinkThulite,
            sevennews: colors.sevennews.red,
            fallback: undefined,
        }),
        color: 'currentColor',
        outline: 'none',
        textDecoration: 'underline',
    },
}))
export const StyledViewAllPackagesButton = styled(StyledButton)(
    ({ theme }) => ({
        display: 'inline',
        color: tokens.thewest.colors.text.primary,
        borderColor: tokens.thewest.colors.text.primary,
        marginBottom: theme.margins.lg,

        '&:hover': {
            background:
                tokens.thewest.colors.actions.button.primary.background.default,
            color: tokens.thewest.colors.actions.button.primary.text.default,
            borderColor:
                tokens.thewest.colors.actions.button.primary.background.default,
        },

        '&:focus': {
            color: tokens.thewest.colors.actions.button.primary.text.default,
        },
    }),
)

export const StyledPromoPageFooter = styled('div')(({ theme }) => ({
    paddingLeft: theme.margins.lg,
    paddingRight: theme.margins.lg,
    textAlign: 'center',
}))

export const StyledBannerImage = styled('img')({
    width: '100%',
    [breakpoint(customBreakpoints.desktop)]: {
        borderRadius: calcRem(32),
    },
})

export const StyledTAndC = styled('div')(({ theme }) => ({
    marginTop: theme.margins.md,
    fontSize: calcRem(14),
    padding: calcRem(0, 16),
}))

export const StyledVerifyButton = styled(StyledButton)<{
    isValid?: boolean
    isSidValid?: boolean
    isEmailValid: boolean
}>(({ theme, isValid, isSidValid, isEmailValid }) => ({
    background: tokens.thewest.colors.palette.westblue,
    color: tokens.thewest.colors.actions.button.primary.text.default,
    borderColor: tokens.thewest.colors.palette.westblue,
    marginTop: 0,
    fontSize: calcRem(18),
    fontWeight: 500,
    textTransform: 'none',
    width: '100%',
    display:
        isValid === undefined || !isValid || !isSidValid || !isEmailValid
            ? 'none'
            : 'block',

    '&:hover': {
        textDecoration: 'underline',
        opacity: 0.9,
    },

    '&:focus': {
        color: tokens.thewest.colors.actions.button.primary.text.default,
    },

    '&[disabled]': {
        textDecoration: 'none',
        background:
            tokens.thewest.colors.actions.button.primary.background.disabled,
        borderColor:
            tokens.thewest.colors.actions.button.primary.background.disabled,
    },
}))

export const StyledTeacherNumberInput = styled('div')<{ isValid?: boolean }>(
    ({ isValid }) => ({
        padding: 0,
        marginBottom: calcRem(20),
        p: {
            fontSize: calcRem(18),
            fontWeight: 800,
        },
        input: {
            textIndent: calcRem(10),
            backgroundColor: '#fff',
            height: calcRem(40),
            outline: 'none',
            width: '100%',
            border: `1px solid ${
                isValid === undefined || isValid
                    ? colors.black
                    : colors.thewest.engineRed
            }`,
        },
        small: {
            color: colors.thewest.engineRed,
            display: isValid === undefined || isValid ? 'none' : 'block',
        },
    }),
)

// Styling for the footy SWG Campaign Offer
export const StyledFootyBenefitList = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: calcRem(20),
})
export const StyledFootyBenefitListItem = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: calcRem(12),

    svg: {
        marginTop: calcRem(8),
        height: calcRem(20),
        width: calcRem(20),
    },
})
export const StyledFootyBenefitListText = styled('p')({
    margin: 0,

    color: '#2B2D2E',
    fontVariantNumeric: 'lining-nums proportional-nums',
    fontFamily: fonts.thewest.serif,
    fontSize: calcRem(16),
    fontWeight: 400,
    lineHeight: 1.5,
})

//2025 University Offer Components
export const UniOfferIntroTextContainer = styled('div')({
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: calcRem(24),
    alignItems: 'center',
})

export const UniOfferIntroTitle = styled('div')({
    fontSize: calcRem(36),
    fontWeight: 700,
    lineHeight: '120%',
    [breakpoint(customBreakpoints.tablet)]: {
        fontSize: calcRem(56),
    },
})

export const UniOfferTextStrikethrough = styled('div')({
    textDecoration: 'line-through',
    textDecorationColor: tokens.perthnow.colors.palette.perthNowRed, //the design uses a PN token
})

export const UniOfferBlueText = styled('span')({
    color: tokens.thewest.colors.palette.universityOffer.campaignBlue,
})

export const UniOfferIntroDescription = styled('div')({
    fontSize: calcRem(16),
    fontWeight: 500,
})

export const UniOfferIntroSave = styled(UniOfferIntroDescription)({
    color: '#032CA2',
    [breakpoint(customBreakpoints.tablet)]: {
        paddingTop: calcRem(8),
    },
})

export const UniOfferImageContainer = styled('div')({
    maxWidth: calcRem(1200),
    paddingLeft: calcRem(30),
    paddingRight: calcRem(30),
})

export const UniOfferImage = styled('img')({
    width: '100%',
    height: '100%',
    objectFit: 'contain',
})

export const UniversityCallToActionTitle = styled('div')({
    fontWeight: 700,
    fontSize: calcRem(32),
    [breakpoint(customBreakpoints.tablet)]: {
        fontSize: calcRem(40),
    },
})

export const StyledUniversityNumberInput = styled('div')<{ isValid?: boolean }>(
    ({ isValid }) => ({
        paddingBottom: calcRem(32),
        paddingTop: calcRem(32),
        display: 'flex',
        flexDirection: 'column',
        gap: calcRem(16),
        input: {
            fontSize: calcRem(16),
            fontWeight: 400,
            textIndent: calcRem(10),
            backgroundColor: '#fff',
            height: calcRem(48),
            borderRadius: calcRem(4),
            outline: 'none',
            width: '100%',
            border: `1px solid ${
                isValid === undefined || isValid
                    ? colors.black10
                    : colors.thewest.engineRed
            }`,
        },
        small: {
            color: colors.thewest.engineRed,
            display: isValid === undefined || isValid ? 'none' : 'block',
        },
    }),
)

export const UniOfferInstructionsTitle = styled('h3')({
    fontSize: calcRem(22),
    fontWeight: 600,
    lineHeight: calcRem(30),
    margin: 0,
    paddingBottom: calcRem(8),
})

export const UniOfferInstructionsDescription = styled('div')({
    fontSize: calcRem(18),
    color: tokens.thewest.colors.text.secondary,
    fontWeight: 400,
    paddingBottom: calcRem(24),
})

export const UniOfferInstructionsListContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: calcRem(16),
})

export const StyledBulletPointWrapper = styled('div')({
    display: 'flex',
    gap: calcRem(12),
    flex: 'none',
})

export const StyledBulletNumbers = styled('div')({
    backgroundColor: 'black',
    color: 'white',
    width: calcRem(24),
    minWidth: calcRem(24),
    height: calcRem(24),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: calcRem(12),
    fontSize: calcRem(14),
    marginTop: calcRem(2),
})

export const SemiboldText = styled('span')({
    fontWeight: 600,
})

export const StyledSubscriptionCopyArea = styled('div')({
    color: tokens.thewest.colors.text.secondary,
    fontSize: calcRem(16),
    maxWidth: calcRem(1250),
    justifySelf: 'center',
    padding: metrics.thewest.margins.md,
    paddingBottom: calcRem(60),
    [breakpoint(customBreakpoints.tablet)]: {
        padding: metrics.thewest.margins.lg,
        paddingBottom: calcRem(183),
    },
})

export const StyledSubscriptionCopyTitle = styled('div')({
    paddingBottom: calcRem(16),
    color: tokens.thewest.colors.text.primary,
})

export const StyledTextLink = styled(TextLink)({
    color: tokens.thewest.colors.text.secondary,
    fontWeight: 600,
})

// Style for the annual package
export const StyledAnnualPackageContainer = styled('div')({
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: calcRem(18),
    flexDirection: 'row',

    [breakpointMax('xs')]: {
        flexDirection: 'column',
    },

    [`${StyledSubscriptionPackage2022}`]: {
        width: '100%',
        margin: 0,
        minWidth: calcRem(268),

        [breakpointMax('xs')]: {
            maxWidth: calcRem(420),
            flexBasis: 'auto',
        },
        [breakpoint(customBreakpoints.tablet)]: {
            minWidth: calcRem(312),
        },
    },
})
