import { BreachScreenType, SwgMetaInfo } from '@news-mono/web-common'
import React from 'react'
import { StyledTextLink } from '../../user-registration/breach/components/BreachWallContent/BreachWallContent.styled'
import { EVERYDAY_DIGITAL } from '../../user-registration/breach/common'
import { StyledBenefitsList } from '../RegionalsSwgPromoPage/RegionalsSwgPromoPage.styled'
import { getTeacherOfferSwgCampaignValues } from './SwgTeacherOfferCampaign'
import { getUniversityOfferSwgCampaignValues } from './SwgUniOfferCampaign'
import { getFooty2025OfferSwgCampaignValues } from './SwgFooty2025OfferCampaign'
import {
    BottomTextWrap,
    InfoText,
    InfoTextLink,
} from '../../user-registration/breach/components/PuzzlesBreach/PuzzlesBreachwall.styled'

export interface SwgCampaignOfferValues {
    isDefault: boolean
    creative: string
    deal: string
    type: BreachScreenType
    sku: string
    price: {
        weekly: number
        originalPrice?: string
        promoPrice?: string
        priceTerms?: string
        billingTerms?: string
    }
    meta?: SwgMetaInfo
    image?: {
        x1: string
        x2: string
    }
    // Component styling values
    BenefitListComponent: JSX.Element
    TermsAndConditionsComponent: JSX.Element | null
    InfoComponent: (
        onAllPackagesClicked: () => void,
        productId: string,
    ) => JSX.Element | null
}

const validSwgCampaignOffers = [
    'footy-pack-2025',
    'teachers-offer',
    'university-offer',
    'annual-package',
] as const
export type SwgCampaignOffer = typeof validSwgCampaignOffers[number]

/**
 * Return if the provided campaign offer (from LaunchDarkly feature 'swg-campaign-offer')
 * is valid for the current environment.
 */
export const isSwgCampaignOfferValid = (
    campaignOffer: string | undefined,
): campaignOffer is SwgCampaignOffer => {
    if (!campaignOffer) {
        return false
    }

    return validSwgCampaignOffers.includes(campaignOffer as SwgCampaignOffer)
}

export const getSwgCampaignOfferValues = (
    campaignOffer: SwgCampaignOffer,
): SwgCampaignOfferValues => {
    switch (campaignOffer) {
        case 'footy-pack-2025':
            return getFooty2025OfferSwgCampaignValues()
        case 'teachers-offer':
            return getTeacherOfferSwgCampaignValues()
        case 'university-offer':
            return getUniversityOfferSwgCampaignValues()
        default:
            return getDefaultSwgCampaignValues()
    }
}

export const getDefaultSwgCampaignValues = (): SwgCampaignOfferValues => {
    return {
        isDefault: true,
        creative: 'TW_Subscribe_With_Google_Campaign',
        deal: 'swg-promo',
        type: 'Premium - Inline',
        sku: 'everyday_digital_4weeks_8',
        price: {
            weekly: EVERYDAY_DIGITAL,
        },
        BenefitListComponent: (
            <>
                <h3>What's included with your subscription?</h3>
                <StyledBenefitsList>
                    <li>
                        In-depth coverage of sport, business, politics,
                        entertainment and more
                    </li>
                    <li>
                        Access to the daily digital edition of the newspaper,
                        plus all features and lift outs
                    </li>
                    <li>
                        Download{' '}
                        <StyledTextLink
                            reverseColor={false}
                            href={`https://apps.apple.com/au/app/the-west-australian/id1198233691`}
                        >
                            The West App&nbsp;
                        </StyledTextLink>
                        to receive news alerts straight to your device
                    </li>
                    <li>
                        A membership to{' '}
                        <StyledTextLink
                            href="https://rewards.thewest.com.au"
                            reverseColor={false}
                        >
                            West Rewards
                        </StyledTextLink>
                        , our subscriber rewards program offering access to
                        exclusive competitions and experiences
                    </li>
                </StyledBenefitsList>
            </>
        ),
        TermsAndConditionsComponent: null,
        InfoComponent: (onAllPackagesClicked, productId) => (
            <React.Fragment>
                <BottomTextWrap>
                    <InfoText>
                        Looking for a different subscription?{' '}
                        <InfoTextLink href={'#'} onEvent={onAllPackagesClicked}>
                            Browse all
                        </InfoTextLink>{' '}
                    </InfoText>
                </BottomTextWrap>
                <p>
                    Subscribe today with Google. Now available and making it
                    easier to access{' '}
                    {productId === 'thewest.com.au' ? 'breaking' : 'your local'}{' '}
                    news.
                </p>
            </React.Fragment>
        ),
    }
}

export const RegionalBenefitList = (productId: string): JSX.Element => {
    return (
        <>
            <h3>What's included with your subscription?</h3>
            <StyledBenefitsList>
                <li>
                    24/7 access to breaking news and exclusive editorial content
                    at{' '}
                    <StyledTextLink
                        reverseColor={false}
                        href={`https://www.${productId}`}
                    >
                        {productId}
                    </StyledTextLink>
                </li>
                <li>
                    A digital subscription to The West Australian including
                    access to{' '}
                    <StyledTextLink
                        href="https://thewest.com.au"
                        reverseColor={false}
                    >
                        thewest.com.au
                    </StyledTextLink>
                    , the West App and the digital edition of each day’s
                    newspaper
                </li>
                <li>
                    Discounts, exclusive offers, and giveaways with West Rewards
                </li>
            </StyledBenefitsList>
        </>
    )
}
