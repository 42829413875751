import { TheWestSection } from '@news-mono/common'
import {
    PublicationType,
    BreachEvent,
    useFeature,
    CardEvents,
    CollectionEvent,
    LinkClickedEvent,
    PlayerEvent,
    Block,
    AllEvents,
} from '@news-mono/web-common'
import {
    RequiredAccessLevel,
    TheWestSections,
} from '@west-australian-newspapers/publication-types'
import React, { useState, useEffect } from 'react'
import { useSection } from '../../../../__helpers'
import { MessageBanner } from '../../../../banners/MessageBanner/MessageBanner'
import { MessageBannerSmall } from '../../../../banners/MessageBannerSmall/MessageBannerSmall'
import {
    useFeatureForRegionalSections,
    useFeatureForSection,
} from '../../../../feature-toggling'
import { AbTestExperiment } from '../../abtesting/AbTestingProvider'
import { useUpdateBreachWallContext, packageOfferDetails } from '../../common'
import {
    BreachWallStyle,
    BreachWallContentType,
} from '../../helpers/BreachWallTypes'
import BreachWallContent from './BreachWallContent'
import {
    StyledTitle,
    StyledMessage,
    StyledTerms,
    StyledLiveTrailerWrap,
    StyledTitleImage,
    StyledBlackFridayTape,
    StyledFooterImage,
} from './BreachWallContent.styled'
import { VideoTrailerCard } from '../../../../cards/VideoTrailerCard/VideoTrailerCard'
import { getBreachWallOptions } from '../../helpers'
import { ArticlePreview } from './ArticlePreview/ArticlePreview'
import { Logger } from 'typescript-log'
import H from 'history'
import debug from 'debug'

const breachWallDebug = debug('breach-wall')

export const defaultBannerText =
    'Subscribers with digital access can view this article.'

export const defaultCreative = 'TW_Article_Breach_WestBlue_Design_PhotoPackages'
export const defaultStyle: BreachWallStyle = 'default-breachwall-2024'
export const defaultDeal = ''
export const defaultType: BreachWallContentType = 'default-price-increase'

export type BreachWallOptions = {
    canShowSportBreach: boolean
    canShowBreachDiscount50afl: boolean
    canShowBreachDiscount50sch: boolean
    canShowBlackFriday: boolean
    canShowBreachDiscountGeneric: boolean
    canShowEofyDiscount: boolean
    canShowBusinessPackDiscount: boolean
    canShowSportPackDiscount: boolean
    canShowPriceIncrease: boolean
    canShowAnnualPackage: boolean
}

export type BannerSize = 'small' | 'large'

export interface BreachWallContentWrapperProps {
    // Supplied events to utilise, else if not provided it'll utilise defaults
    suppliedDeal?: string
    suppliedType?: BreachWallContentType
    suppliedCreative?: string
    suppliedStyle?: BreachWallStyle
    // Supplied AB testing parameters, else utilise new parameters
    suppliedExperiment?: AbTestExperiment
    // Publication data to update breach wall context
    publication?: PublicationType
    requiredAccess?: RequiredAccessLevel
    location: H.Location
    // Consume all events
    onEvent: (event: AllEvents) => void
    // Values to set the top "banner" values
    bannerSize?: BannerSize
    bannerText?: string
    // Overriding values used from within the breach screen itself
    title?: string
    message?: string
    terms?: string
    log: Logger
    blocks?: Block[]
    forceLoad?: boolean
    isTest?: boolean
    // Optional properties that can be passed on a per-campaign basis
    contentOptions?: {
        [key: string]: any
    }
}

export const BreachWallContentWrapper: React.FC<BreachWallContentWrapperProps> =
    ({
        suppliedDeal,
        suppliedType,
        suppliedCreative,
        suppliedStyle,
        suppliedExperiment,
        publication,
        requiredAccess,
        onEvent,
        bannerSize,
        bannerText,
        title,
        message,
        terms,
        log,
        blocks,
        forceLoad,
        isTest,
        contentOptions,
        location,
    }) => {
        const section = useSection<TheWestSection>()
        const isRegionalOverride = useFeatureForRegionalSections(
            'breach-regional-override',
            section,
        )

        // New Breach Design from optimisely 2024
        const isNewBreach2024 =
            useFeatureForSection({
                feature: 'breach-v2-2024',
                sections: TheWestSections.default,
            }) ||
            isTest === true ||
            isRegionalOverride

        const showPartOfArticle = useFeatureForSection({
            feature: 'tp-subscription-breach-show-part-article',
            sections: TheWestSections.default,
        })

        const otherBreachOptions = useBreachWallOptions()

        const [isLoading, setLoading] = useState(
            forceLoad === undefined ? true : !forceLoad,
        )

        const shouldShowLiveSportsTrailer =
            contentOptions && contentOptions.showLiveSportTrailer

        const [breachDeal, setBreachDeal] = useState<string>(
            suppliedDeal || defaultDeal,
        )

        const [breachType, setBreachType] = useState<BreachWallContentType>(
            suppliedType || defaultType,
        )

        const [breachStyle, setBreachStyle] = useState<BreachWallStyle>(
            suppliedStyle || defaultStyle,
        )

        const breachCreative = suppliedCreative || defaultCreative

        useEffect(() => {
            // force load is used and so it's probably coming from a test
            // so we don't want it to do any calculation, as it should be
            // supplied with everything it needs
            if (forceLoad === true) {
                return
            }
            // if it's not utilising the new tp breach designs, we don't want to try
            // and load a custom breach screen styling over it.
            if (!isNewBreach2024) {
                setLoading(false)
                return
            }

            const otherBreachWall = getBreachWallOptions(
                location,
                otherBreachOptions,
            )
            if (otherBreachWall !== undefined) {
                setBreachDeal(otherBreachWall.deal)
                setBreachType(otherBreachWall.type)
                setBreachStyle(otherBreachWall.style)
            }

            setLoading(false)
        }, [
            forceLoad,
            isLoading,
            location,
            otherBreachOptions,
            isNewBreach2024,
        ])

        // fire breach wall context update
        useUpdateBreachWallContext({
            publication: publication,
            requiredAccessLevel: requiredAccess || 'subscriber',
            breachDeal: breachDeal,
            breachCreativeName: breachCreative,
        })

        const subscriptionPackages = packageOfferDetails(
            breachDeal === 'epaper-regional'
                ? 'digital-only'
                : breachType
                ? breachType
                : defaultType,
        )

        // some logging so that we have relevant information on the breach screen available.
        if (!isLoading || forceLoad) {
            breachWallDebug(
                'Breach screen type has been determined, now about to generate a BreachWallContent with the following values:',
            )
            breachWallDebug('ContentType:', breachType)
            breachWallDebug('Style:', breachStyle)
            breachWallDebug('Deal:', breachDeal)
        }

        return (
            <>
                {!isNewBreach2024 && showPartOfArticle && publication && (
                    <ArticlePreview
                        blocks={blocks || []}
                        numberOfBlocksToPreview={2}
                        log={log}
                        onEvent={onEvent}
                    />
                )}
                <div data-testid="BreachScreen">
                    {/* {!isNewBreach2024 && getBreachBanner(
                        bannerText || defaultBannerText,
                        bannerSize || 'small',
                        onEvent,
                    )} */}
                    {!isLoading && (
                        <BreachWallContent
                            breachCreativeName={breachCreative}
                            breachType={breachType}
                            breachDeal={breachDeal}
                            breachStyle={breachStyle}
                            isTest={isTest}
                            onEvent={onEvent}
                            title={() => {
                                if (
                                    breachStyle === 'black-friday' ||
                                    breachStyle === 'eofy' ||
                                    breachStyle === 'afl50'
                                ) {
                                    return (
                                        <StyledTitleImage
                                            src={getTitleBannerImage(
                                                breachStyle,
                                            )}
                                        />
                                    )
                                } else {
                                    return (
                                        <React.Fragment>
                                            {title && (
                                                <StyledTitle>
                                                    {title}
                                                </StyledTitle>
                                            )}
                                            {message && (
                                                <StyledMessage>
                                                    {message}
                                                </StyledMessage>
                                            )}
                                        </React.Fragment>
                                    )
                                }
                            }}
                            //appears above terms
                            footer={() => {
                                if (breachStyle === 'afl50') {
                                    return (
                                        <StyledFooterImage
                                            src={getFooterImage(breachStyle)}
                                        />
                                    )
                                }

                                return null
                            }}
                            terms={() => {
                                if (terms) {
                                    return <StyledTerms>{terms}</StyledTerms>
                                }

                                return null
                            }}
                            //Media banner appears above the title
                            mediaBanner={() => {
                                if (shouldShowLiveSportsTrailer) {
                                    return (
                                        <StyledLiveTrailerWrap>
                                            <VideoTrailerCard
                                                layoutMode="double"
                                                onEvent={() => null}
                                            />
                                        </StyledLiveTrailerWrap>
                                    )
                                }
                                if (breachStyle === 'black-friday') {
                                    const tape = require('./assets/thewest-tape-black-friday-1.png')

                                    return (
                                        <>
                                            <StyledBlackFridayTape
                                                src={tape}
                                                breachStyle={breachStyle}
                                                className="left-tape"
                                            />
                                            <StyledBlackFridayTape
                                                src={tape}
                                                breachStyle={breachStyle}
                                                className="right-tape"
                                            />
                                            <StyledBlackFridayTape
                                                src={tape}
                                                breachStyle={breachStyle}
                                                className="bottom-tape"
                                            />
                                        </>
                                    )
                                }

                                return null
                            }}
                            //Breach banner: "Subscribers with digital access can view this article."
                            breachBanner={() => {
                                return getBreachBanner(
                                    bannerText || defaultBannerText,
                                    bannerSize || 'small',
                                    onEvent,
                                    isNewBreach2024,
                                    breachStyle === 'black-friday' ||
                                        breachStyle === 'afl50'
                                        ? true
                                        : false,
                                )
                            }}
                            subscriptionPackages={subscriptionPackages}
                            isRegionalOverride={isRegionalOverride}
                        />
                    )}
                </div>
            </>
        )
    }

function getBreachBanner(
    bannerText: string,
    bannerSize: BannerSize,
    onEvent: (event: BreachEvent) => void,
    isNewBreach?: boolean,
    isLightText?: boolean,
): JSX.Element {
    return bannerSize === 'large' && !isNewBreach ? (
        <MessageBanner
            icon="subscriber"
            connector="solid-arrow"
            onEvent={onEvent}
            signupOrigin={'breach-screen'}
        >
            <p>{bannerText}</p>
        </MessageBanner>
    ) : (
        <MessageBannerSmall
            icon="subscriber"
            onEvent={onEvent}
            isNewBreach2024={isNewBreach}
            isLightText={isLightText}
            signupOrigin={'breach-screen'}
        >
            <p>{bannerText}</p>
        </MessageBannerSmall>
    )
}

function getTitleBannerImage(breachStyle: BreachWallStyle): string | undefined {
    switch (breachStyle) {
        case 'afl50':
            return require('./assets/afl_50_banner.png')
        case 'black-friday':
            return require('./assets/thewest-banner-black-friday.png')
        case 'eofy':
            return require('./assets/thewest-digital-hero-variation-eofy-banner.png')
    }
}

function getFooterImage(breachStyle: BreachWallStyle): string | undefined {
    switch (breachStyle) {
        case 'afl50':
            return require('./assets/WA_footy_lives_here_logo.png')
    }
}

function useBreachWallOptions(): BreachWallOptions {
    return {
        canShowSportBreach: useFeature('sport-breach-screen-variant'),
        canShowBreachDiscount50sch: useFeature('top-50-schools'),
        canShowBlackFriday: useFeature('black-friday'),
        canShowBreachDiscountGeneric: useFeature('50-off-breach-discount'),
        canShowEofyDiscount: useFeature('subs-campaign-eofy-weekend-digital'),
        canShowPriceIncrease: useFeature('default-price-increase'),
        canShowBusinessPackDiscount: useFeature(
            'subs-campaign-business-pack-2022',
        ),
        canShowSportPackDiscount: useFeature('subs-campaign-sport-pack-2022'),
        canShowBreachDiscount50afl: useFeature('50-off-afl-breach-screen'),
        canShowAnnualPackage: useFeature('breach-show-annual-package'),
    }
}

BreachWallContentWrapper.displayName = 'BreachWallContentWrapper'

export default BreachWallContentWrapper
