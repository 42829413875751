import { useTheme } from '@emotion/react'
import { TheWestSection } from '@news-mono/common'
import {
    BreachEvent,
    click_origin_breach_screen,
    DataLayerEventName,
    useToggleState,
    ViewSubscriptionItem,
} from '@news-mono/web-common'
import {
    TheWestRegionalSections,
    TheWestSections,
} from '@west-australian-newspapers/publication-types'
import React, { useEffect } from 'react'
import {
    StyledBreachWallContent2022,
    StyledRegistrationInfo,
    StyledSubTitle,
    StyledTerms2022,
    StyledTitle,
    StyledPackagesNew2024,
} from './BreachWallContent.styled'
import {
    BreachWallContentType,
    BreachWallStyle,
} from '../../helpers/BreachWallTypes'
import { useSection } from '../../../../__helpers'
import { getAttribution } from '../../../../errors/ErrorPage'
import { useFeatureForSection } from '../../../../feature-toggling'
import { LinkContext } from '../../../../navigation'
import { toItemListId, DEFAULT_CART_QUANTITY } from '../../../../tracking'
import { useAuthActions } from '../../../authentication'
import { SubscriptionPackages } from '../../common'
import { createLinkClickEvent } from '../../createLinkClickHandler'
import SubscriptionPackage from '../SubscriptionPackage/SubscriptionPackage'

export interface BreachWallContentProps {
    title: () => JSX.Element | null
    terms: () => JSX.Element | null
    footer: () => JSX.Element | null
    mediaBanner: () => JSX.Element | null
    breachBanner: () => JSX.Element | null
    subscriptionPackages?: SubscriptionPackages
    // Values passed down from the BreachWallContentWrapper in relation to the breach
    breachCreativeName: string
    breachType: BreachWallContentType
    breachDeal: string
    breachStyle: BreachWallStyle
    onEvent: (event: BreachEvent) => void
    // Values passed down if it's a test BreachWallContent
    isTest: boolean | undefined
    isRegionalOverride: boolean
}

const backgroundImagePrefix = './assets/thewest-digital-hero-variation-'
export const BreachWallContent: React.FC<BreachWallContentProps> = ({
    title,
    footer,
    subscriptionPackages,
    breachCreativeName,
    breachType,
    breachDeal,
    breachStyle,
    onEvent,
    mediaBanner,
    breachBanner,
    terms,
    isTest,
    isRegionalOverride,
}) => {
    const renderFooter = footer
    const renderMediaBanner = mediaBanner
    const renderTitleSection = title
    const renderTerms = terms
    const renderBanner = breachBanner
    const { onSubscribeClick } = useAuthActions()
    const theme = useTheme()
    const masthead =
        getAttribution(theme.kind, theme.section) || 'The West Australian'
    const section = useSection<TheWestSection>()

    const isNewBreach2024 =
        useFeatureForSection({
            feature: 'breach-v2-2024',
            sections: TheWestSections.default,
        }) ||
        isTest === true ||
        isRegionalOverride

    const isRegional = !isRegionalOverride && section in TheWestRegionalSections

    const backgroundImageVariation = useBackgroundImageVariation(breachStyle)
    const showBreachBanner = canShowBreachBanner(breachStyle) && !isRegional

    // handle whenever 'subscription options' is clicked.
    const onAllPackagesClicked = React.useCallback(() => {
        createLinkClickEvent(breachCreativeName, breachDeal, onEvent)()
        onSubscribeClick({
            callToAction: click_origin_breach_screen,
            breachScreenType: 'Premium - Inline',
        })
    }, [breachCreativeName, breachDeal, onEvent, onSubscribeClick])

    const ContentContainer = StyledBreachWallContent2022

    let largeImage = undefined
    let smallImage = undefined

    // Keep for next season and add back in
    const afl50PackageBgImageDesktop = require('./assets/white_halftone_desktop.png')
    const afl50PackageBgImageMobile = require('./assets/white_halftone_mobile.png')
    const afl50FooterLogo = require(`./assets/WA_footy_lives_here_logo.png`)
    const afl50HeaderBanner = require(`./assets/afl_50_banner.png`)

    // Assign background image if utilising new breach design.
    if (isNewBreach2024) {
        if (breachDeal === 'black-friday') {
            largeImage = require(`${backgroundImagePrefix}${backgroundImageVariation}.jpg`)
            smallImage = require(`${backgroundImagePrefix}${backgroundImageVariation}-cropped.jpg`)
        } else if (breachDeal === 'AFL50') {
            largeImage = require(`${backgroundImagePrefix}${backgroundImageVariation}.png`)
            smallImage = require(`${backgroundImagePrefix}${backgroundImageVariation}-mobile.png`)
        } else {
            largeImage = undefined
            smallImage = undefined
        }
    }

    // when everything is loaded, fire off a view_item_list GA4 event
    useEffect(() => {
        const availableDeal = breachDeal === '' ? 'default' : breachDeal
        const breachAvailablePayload: BreachEvent['payload'] = {
            paywallType: 'freemium',
            leadDepth: 0,
            creative: breachCreativeName,
            deal: availableDeal,
            breachScreenType: 'Premium - Inline',
            popupMethod: 'auto',
            pricepoint: [7, 8],
        }
        onEvent({
            type: DataLayerEventName.breachScreenAvailable,
            originator: 'breachscreen',
            payload: breachAvailablePayload,
        })

        const viewSubscriptionItems: ViewSubscriptionItem[] =
            subscriptionPackages?.map((pkg, index) => ({
                index,
                deal_name: breachType,
                item_name: pkg.heading,
                item_id: pkg.rateCode,
                item_brand: masthead,
                component_path: pkg.packagePath,
                item_list_category: 'freemium',
                item_list_category2: 'Premium - Inline',
                item_list_name: breachCreativeName,
                item_list_id: toItemListId(breachCreativeName),
                price: pkg.weeklyPrice,
                promo_price: pkg.promoPrice || 0,
                discount: pkg.discount || 0,
                coupon: pkg.offerCode || '',
                quantity: DEFAULT_CART_QUANTITY,
                currency: 'AUD',
            })) || []
        onEvent({
            type: DataLayerEventName.viewSubscriptionItemListEvent,
            parentType: DataLayerEventName.viewItemListEvent,
            originator: 'breachscreen',
            payload: { items: viewSubscriptionItems },
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // New 2024 Optimizely Breachwall design
    return (
        <>
            <ContentContainer
                theme={theme}
                backgroundColor={isNewBreach2024 ? '#F4F5F5' : undefined}
                largeImage={largeImage}
                smallImage={smallImage}
                className={breachStyle}
            >
                {renderMediaBanner()}
                {breachStyle != 'default-breachwall-2024' && (
                    <StyledRegistrationInfo>
                        {breachStyle && renderTitleSection()}
                    </StyledRegistrationInfo>
                )}

                {breachDeal === 'default-annual-package' && (
                    <div className="default-annual-title-block">
                        <AnnualPackageTitleBlock />
                    </div>
                )}

                <StyledPackagesNew2024
                    breachStyle={breachStyle}
                    className={
                        breachDeal === 'default-annual-package'
                            ? 'default-annual-packages'
                            : undefined
                    }
                >
                    {subscriptionPackages &&
                        subscriptionPackages.map(
                            (subscriptionPackageItem, index) => (
                                <SubscriptionPackage
                                    key={index}
                                    breachCreativeName={breachCreativeName}
                                    breachDeal={breachDeal}
                                    breachStyle={breachStyle}
                                    breachContent={
                                        showBreachBanner
                                            ? breachType
                                            : undefined
                                    }
                                    onEvent={onEvent}
                                    packageInfo={subscriptionPackageItem}
                                    masthead={masthead}
                                    isRegionalOverride={isRegionalOverride}
                                />
                            ),
                        )}
                    {renderFooter()}
                    <LinkContext.Consumer>
                        {() => (
                            <StyledTerms2022
                                breachStyle={breachStyle}
                                className={
                                    breachDeal === 'default-annual-package'
                                        ? 'optimise-blue'
                                        : undefined
                                }
                            >
                                *See other{' '}
                                <a href="#" onClick={onAllPackagesClicked}>
                                    subscription options
                                </a>{' '}
                                and{' '}
                                <a
                                    href={
                                        'https://subscriber.thewest.com.au/subscription-terms'
                                    }
                                    target="_blank"
                                >
                                    Terms &amp; Conditions
                                </a>
                            </StyledTerms2022>
                        )}
                    </LinkContext.Consumer>
                    {isNewBreach2024 && renderBanner()}
                </StyledPackagesNew2024>
            </ContentContainer>
        </>
    )
}

function useBackgroundImageVariation(breachStyle: BreachWallStyle) {
    const backgroundVariation =
        useToggleState()?.['tp-subscription-hero-variation'] || 'a'

    // check for the background variation against the breachStyle
    switch (breachStyle) {
        case 'vanishing-cousins':
            return 'vanishing-cousins'
        case 'black-friday':
            return 'black-friday'
        case 'eofy':
            return 'eofy'
        case 'afl50':
            return 'afl50'
    }

    return backgroundVariation
}

function canShowBreachBanner(breachStyle: BreachWallStyle) {
    if (
        breachStyle === 'black-friday' ||
        breachStyle === 'eofy' ||
        breachStyle === 'afl50' ||
        breachStyle !== 'default-breachwall-2024'
    ) {
        return true
    }
    return false
}

const AnnualPackageTitleBlock = () => {
    return (
        <StyledTitle className="default-annual-title">
            The No. 1 source of news for every West Australian
        </StyledTitle>
    )
}

BreachWallContent.displayName = 'BreachWallContent'
export default BreachWallContent
